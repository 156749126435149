import { BadRequest } from '@tsed/exceptions';
import type { GetStaticProps, InferGetStaticPropsType } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import LoginForm from '@/components/profile/LoginForm';
import { urls } from '../constant/urls';
import useUser from '../lib/hooks/useUser';
import { homeConfig, commonConfig } from '../lib/i18n/configs';

type Props = {
  /** Add Route props here */
};

export default function HomeIndex(
  _props: InferGetStaticPropsType<typeof getStaticProps>
) {
  const { user, isLoading } = useUser();
  const router = useRouter();
  useEffect(() => {
    if (!isLoading && !user.id) {
      if (window.location.hostname === 'www.aieia.com') {
        router.push(urls.login);
      } else if (window.location.hostname === 'www.aieias.com') {
        router.push(urls.casa98);
      }
    }

    if (!isLoading && user.id && router.pathname == '/') {
      router.push(urls.play);
    }
  }, [router, user, isLoading]);

  return (
    <>
      <LoginForm name={'loginFormPlay'} />
    </>
  );
}

export const getStaticProps: GetStaticProps<Props> = async (context) => {
  const { locale } = context;
  if (locale === undefined) {
    throw new BadRequest('locale is missing');
  }
  const i18nNamespaces = [
    ...homeConfig.i18nNamespaces,
    ...commonConfig.i18nNamespaces,
  ];

  return {
    props: {
      ...(await serverSideTranslations(locale, i18nNamespaces.slice())),
    },
  };
};
